import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    width: 100vw;
    height: 100vh;
    font-family: Helvetica, serif;
  }
`
