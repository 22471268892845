export const colors = {
    primary: '#3780DE',
    darkerPrimary: '#4871EB',
    white: '#ffffff',
    black: '#000000',
    transparent: 'transparent',
    linkedIn: '#0077B7',
    success: '#27ae60',
    red: '#e74c3c',
    button: '#6BD89A',
    gray: {
        lightGray: '#ecf0f1',
        mediumGray: '#bdc3c7',
        darkGray: '#9ca2a7',
        veryDark: '#707070',
        border: '#F8F9FC'
    },
    background: {
        darkPrimary: '#A30031',
        header: '#22313D',
        lightGray: '#F8F8F8',
        custom: '#F8FAFD'
    },
    typography: {
        regular: '#273B4A',
        light: '#737373',
        gray: '#4A4A4A',
        primaryBackground: '#FFC7C7',
        lightGray: '#B5B5B5',
        lightRegular: '#ABABAB'
    },
    icon: {
        gray: '#C4C4C4'
    }
}
