import React from 'react'
import { stylesUtils } from 'lib/utils'

export const LayoutBody: React.FunctionComponent = ({ children }) => (
    <div style={styles.wrapper}>
        <div style={styles.innerWrapper}>
            {children}
        </div>
    </div>
)

const styles = stylesUtils.makeStyles(({ colors }) => ({
    wrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: colors.white
    },
    innerWrapper: {
        width: '100%'
    }
}))
