import React, { useCallback } from 'react'
import styled from 'styled-components'
import { constants } from 'common'
import { Icons, images } from 'assets'
import { colors } from 'lib/styles'
import { useTranslations } from 'lib/hooks'
import { Typography, Button } from 'lib/components'

export const About: React.FunctionComponent = () => {
    const T = useTranslations()

    const renderCheck = useCallback((text: string) => (
        <CheckWrapper>
            <Icons.Check color={colors.button}/>
            <CheckText>
                {text}
            </CheckText>
        </CheckWrapper>
    ), [])

    return (
        <Container id={constants.aboutMeId}>
            <Body>
                <LeftSection>
                    <Title>
                        {T.pages.main.about.aboutMe}
                    </Title>
                    {renderCheck(T.pages.main.about.firstCheck)}
                    {renderCheck(T.pages.main.about.secondCheck)}
                    {renderCheck(T.pages.main.about.thirdCheck)}
                    <MobileImage src={images.aboutImg}/>
                    <Text>
                        {T.pages.main.about.text}
                    </Text>
                    <Link
                        target="_blank"
                        href="https://t.me/relocat_pl"
                    >
                        <CustomButton>
                            <TelegramWrapper>
                                <Icons.Telegram color={colors.white}/>
                            </TelegramWrapper>
                            <ButtonText>
                                {T.pages.main.about.button}
                            </ButtonText>
                        </CustomButton>
                    </Link>
                </LeftSection>
                <Image src={images.aboutImg}/>
            </Body>
        </Container>
    )
}

const Container = styled.div`
  width: 100%;
  background-color: ${colors.background.custom};
  display: flex;
  justify-content: center;
  overflow: hidden;
`

const Body = styled.div`
  max-width: ${constants.maxBodyWidth}px;
  position: relative;
  display: flex;
  align-items: center;
  @media (max-width: 1200px) {
    max-width: 900px;
  }
  @media (max-width: 978px) {
    padding: 0 20px;
    flex-direction: column;
  }
`

const Title = styled(Typography)`
  font-size: 36px;
  font-weight: bold;
  line-height: 30px;
  margin-top: 90px;
  margin-bottom: 60px;
  @media (max-width: 978px) {
    font-size: 22px;
    line-height: unset;
    margin-top: 20px;
    margin-bottom: 30px;
  }
`

const Text = styled(Typography)`
  font-size: 14px;
  line-height: 24px;
  word-break: normal;
  color: ${colors.typography.lightRegular};
  margin-top: 60px;
  text-align: center;
`

const CheckWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`

const CheckText = styled(Typography)`
  font-size: 18px;
  line-height: 34px;
  margin-left: 10px;
  display: flex;
  flex: 1;
  @media (max-width: 978px) {
    font-size: 16px;
  }
`

const TelegramWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin-right: 30px;
`

const CustomButton = styled(Button)`
  margin-bottom: 100px;
  margin-top: 40px;
`

const ButtonText = styled(Typography)`
  color: ${colors.white};
`

const LeftSection = styled.div`
  width: 50%;
  @media (max-width: 978px) {
    width: 100%;
  }
`

const Image = styled.img`
  position: absolute;
  width: 50%;
  left: 55%;
  @media (max-width: 978px) {
    display: none;
  }
`

const MobileImage = styled.img`
  display: none;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  @media (max-width: 978px) {
    display: block;
  }
`

const Link = styled.a`
  outline: none;
  text-decoration: none;
`
