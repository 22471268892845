import { useEffect } from 'react'

export const useWindowClick = (callback: VoidFunction) => {
    useEffect(() => {
        const fn = () => callback()

        window.addEventListener('click', fn)

        return () => {
            window.removeEventListener('click', fn)
        }
    }, [callback])
}
