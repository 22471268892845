import React from 'react'
import { Helmet } from 'react-helmet'
import { queries } from 'lib/utils'

type SeoProps = {
    title?: string,
    description?: string,
    image?: string,
    canonical?: string
}

export const Seo: React.FunctionComponent<SeoProps> = ({
    title,
    description,
    image,
    canonical
}) => {
    const { site } = queries.getSiteMetadata()
    const metaDescription = description || site.siteMetadata.description
    const metaTitle = title || site.siteMetadata.title
    const linkCanonical = canonical || site.siteMetadata.canonical

    return (
        <Helmet
            htmlAttributes={{
                lang: 'pl'
            }}
            title={metaTitle}
            meta={[
                {
                    name: `description`,
                    content: metaDescription
                },
                {
                    property: `og:title`,
                    content: metaTitle
                },
                {
                    property: `og:description`,
                    content: metaDescription
                },
                {
                    property: `og:image`,
                    content: image
                },
                {
                    property: `og:type`,
                    content: `website`
                },
                {
                    property: 'keywords',
                    content: site.siteMetadata.keywords
                }
            ]}
            link={[
                {
                    rel: 'canonical',
                    href: linkCanonical
                }
            ]}
        >
            <script src="https://widget.clutch.co/static/js/widget.js"/>
        </Helmet>
    )
}
