import { en_GB } from './en_GB'
import { ua_UA } from './ua_UA'
import { ru_RU } from './ru_RU'

export enum Languages {
    en_GB = 'en-GB',
    ru_RU = 'ru-RU',
    ua_UA = 'ua-UA'
}

export const languages = {
    [Languages.en_GB]: en_GB,
    [Languages.ru_RU]: ru_RU,
    [Languages.ua_UA]: ua_UA
}
