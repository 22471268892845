import React from 'react'
import styled from 'styled-components'
import { Typography } from 'lib/components'
import { colors, stylesHelpers } from 'lib/styles'

type OneProsProps = {
    title: string,
    rowIndex: number,
    color: string,
    left?: boolean
}

type OneProsStyle = {
    color?: string,
    left?: boolean
}

export const OnePros: React.FunctionComponent<OneProsProps> = ({
    rowIndex,
    title,
    color,
    left
}) => (
    <Container left={left}>
        <TextWrapper>
            <Text>
                {title}
            </Text>
        </TextWrapper>
        <Number
            left={left}
            color={color}
        >
            {rowIndex}
        </Number>
    </Container>
)

const Container = styled.div<OneProsStyle>`
  display: flex;
  flex-direction: ${props => props.left ? 'row' : 'row-reverse'};
  margin-bottom: 50px;
  box-shadow: 0 4px 10px 0 ${stylesHelpers.customBlackTransparent(0.25)};
  border-radius: 16px;
  overflow: hidden;
  @media (max-width: 978px) {
    flex-direction: row;
    margin-bottom: 10px;
    z-index: 10;
    width: 50%;
    margin-left: auto;
  }
`

const Number = styled.div<OneProsStyle>`
  background-color: ${props => props.color};
  padding: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${props => props.left ? 'auto' : 'unset'};
  margin-right: ${props => props.left ? 'unset' : 'auto'};
  @media (max-width: 978px) {
    width: 10px;
    padding: 0;
    font-size: 0;
    margin-left: unset;
    margin-right: unset;
  }
`

const Text = styled(Typography)`
  font-size: 12px;
  padding: 15px;
  max-width: 200px;
  word-break: break-word;
  text-align: left;
  flex: 1;
  @media (max-width: 978px) {
    width: 100%;
    max-width: unset;
  }
`

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  flex: 1;
  min-height: 60px;
  background-color: ${colors.white};
`
