import styled from 'styled-components'
import { colors } from 'lib/styles'

export const Button = styled.button`
  background-color: ${colors.button};
  border-radius: 50px;
  padding: 20px 30px;
  outline: none;
  text-decoration: none;
  border: none;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${colors.white};
  font-size: 18px;
  justify-content: center;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 978px) {
    font-size: 14px;
    padding: 10px 15px;
  }
`
