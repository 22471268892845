import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors } from 'lib/styles'
import { useResize, useWindowClick } from 'lib/hooks'
import { Tab } from './Tab'
import { Tab as TabModel } from './types'

type TableProps = {
    tabs: Array<TabModel>
}

export const TabTable: React.FunctionComponent<TableProps> = ({
    tabs
}) => {
    const [ active, setActive ] = useState<number | undefined>(1)
    const { window: { width } } = useResize()

    useWindowClick(() => width > 978 && setActive(undefined))

    useEffect(() => {
        if (width < 978) {
            setActive(1)
        }
    }, [width])

    return (
        <Container onClick={event => event.stopPropagation()}>
            {tabs.map((item, index) => (
                <Tab
                    key={index}
                    {...item}
                    first={index === 0}
                    rowIndex={index + 1}
                    isActive={active === index + 1}
                    last={index === tabs.length - 1}
                    anyActive={typeof active !== 'undefined'}
                    onClick={value => {
                        if (width < 978 && active === value) {
                            return
                        }

                        if (active === value) {
                            return setActive(undefined)
                        }

                        setActive(value)
                    }}
                />
            ))}
        </Container>
    )
}

const Container = styled.div`
  display: flex;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  background-color: ${colors.white};
  width: 100%;
  position: relative;
  justify-content: center;
  overflow: hidden;
  @media (max-width: 978px) {
    overflow: hidden;
  }
`
