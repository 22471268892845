import styled from 'styled-components'
import { colors } from 'lib/styles'

export const Typography = styled.div`
  font-size: 18px;
  color: ${colors.typography.regular};
  white-space: pre-line;
  @media (max-width: 978px) {
    font-size: 14px;
  }
`
