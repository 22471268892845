import TinyColor from 'tinycolor2'

export const stylesHelpers = {
    customWhiteTransparent: (opacity: number) => `rgba(255, 255, 255, ${opacity})`,
    customBlackTransparent: (opacity: number) => `rgba(0, 0, 0, ${opacity})`,
    hexToRGBA: (hex: string, opacity: number) => hex
        .replace('#', '')
        .split(/(?=(?:..)*$)/)
        .map(x => parseInt(x, 16))
        .filter(num => !isNaN(num))
        .reduce((acc, color) => `${acc}${color},`, 'rgba(')
        .concat(`${opacity})`),
    /** amount is a number from 0 to 100 -> percentage */
    darkenColor: (hex: string, amount: number) => TinyColor(hex)
        .darken(amount)
        .toString(),
    lightenColor: (hex: string, amount: number) => TinyColor(hex)
        .brighten(amount)
        .toString()
}
