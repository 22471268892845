import { stylesHelpers } from 'lib/styles'

export const aboutMeId = 'aboutMeId'
export const servicesId = 'servicesId'
export const prosId = 'prodId'
export const contactId = 'contactId'
export const maxBodyWidth = 1000
export const mediaQuery = {
    tablet: 1500,
    smallTablet: 1280,
    smallTablet991: 991,
    phone: 767,
    phone575: 575,
    phone419: 419
}
export const BOX_SHADOW = `2px 4px 12px ${stylesHelpers.customBlackTransparent(0.08)}`
