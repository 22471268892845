import React from 'react'
import styled from 'styled-components'
import { RenderPaginationProps } from 'react-smart-scroller'
import { colors } from 'lib/styles'

type PaginationProps = {
    options: RenderPaginationProps,
    style?: React.CSSProperties,
    dotColor?: string
}

type DotStyles = {
    selected: boolean,
    dotColor?: string
}

export const Pagination: React.FunctionComponent<PaginationProps> = ({
    options,
    style,
    dotColor
}) => (
    <Container style={style}>
        {Array(options.childrenCount).fill(undefined).map((_, index) => (
            <Dot
                key={index}
                dotColor={dotColor}
                selected={index === options.selectedDot}
                onClick={() => options.onDotClick(index)}
            />
        ))}
    </Container>
)

const Container = styled.div`
  display: flex;
  width: 100%;
  margin-top: 30px;
  align-items: center;
  justify-content: center;
  height: 25px;
  :last-child {
    margin-right: 0;
  }
`

const Dot = styled.div<DotStyles>`
  width: 6px;
  height: ${props => props.selected ? 18 : 6}px;
  border-radius: 3px;
  background-color: ${props => props.selected ? colors.success : props.dotColor || colors.typography.regular};
  margin-right: 20px;
  transition: height 0.3s;
  cursor: pointer;
  
`
