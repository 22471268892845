import React from 'react'
import styled from 'styled-components'
import { constants } from 'common'
import { colors } from 'lib/styles'
import { Body, Form, LeftSection, Footer } from '../components/contact'

export const Contact: React.FunctionComponent = () => (
    <Body id={constants.contactId}>
        <Container>
            <Left>
                <LeftSection/>
            </Left>
            <Right>
                <Form/>
            </Right>
        </Container>
        <Bottom>
            <Footer/>
        </Bottom>
    </Body>
)

const Container = styled.div`
  display: flex;
  padding: 90px 0;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 1200px) {
    width: 900px;
  }
  @media (max-width: 978px) {
    width: 100%;
    flex-direction: column;
    padding: 50px 0;
  }
`

const Left = styled.div`
  width: 50%;
  @media (max-width: 978px) {
    width: 100%;
  }
`

const Right = styled.div`
  width: 50%;
  @media (max-width: 978px) {
    width: 400px;
  }
  @media (max-width: 445px) {
    width: 100%;
  }
`

const Bottom = styled.div`
  margin-bottom: 90px;
  background-color: ${colors.white};
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 30px;
  width: 110%;
  box-sizing: border-box;
  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 1100px) {
    width: 90%;
  }
  @media (max-width: 978px) {
    width: 400px;
    margin-right: auto;
  }
  @media (max-width: 445px) {
    width: 100%;
  }
`
