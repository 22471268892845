import React from 'react'
import styled from 'styled-components'
import { constants } from 'common'
import { flags, Icons } from 'assets'
import { colors } from 'lib/styles'
import { Languages } from 'lib/locale'
import { Link, Typography } from 'lib/components'
import { useLanguage, useNavigateScroll, usePopper, useTranslations } from 'lib/hooks'

type HeaderProps = {
    footer?: boolean
}

export const Header: React.FunctionComponent<HeaderProps> = ({
    footer
}) => {
    const T = useTranslations()
    const { languageState: { stateLanguage }, languageActions: { setLanguage } } = useLanguage()
    const [ extractedLang ] = stateLanguage
        .split('-')
    const { popperActions: { setPopperState } } = usePopper()
    const onNavigate = useNavigateScroll()

    return (
        <Container>
            <LogoWrapper>
                <Icons.Logo color={footer ? colors.typography.regular : undefined}/>
                <LogoText footer={footer}>
                    {T.common.logo}
                </LogoText>
            </LogoWrapper>
            <MiddleWrapper>
                <Navigate
                    footer={footer}
                    onClick={() => onNavigate(constants.servicesId)}
                >
                    {T.pages.main.first.services}
                </Navigate>
                <Navigate
                    footer={footer}
                    onClick={() => onNavigate(constants.prosId)}
                >
                    {T.pages.main.first.pros}
                </Navigate>
                <Navigate
                    footer={footer}
                    onClick={() => onNavigate(constants.aboutMeId)}
                >
                    {T.pages.main.first.about}
                </Navigate>
                <Navigate
                    footer={footer}
                    onClick={() => onNavigate(constants.contactId)}
                >
                    {T.pages.main.first.contact}
                </Navigate>
            </MiddleWrapper>
            {!footer && (
                <LanguagesWrapper>
                    <LinksWrapper>
                        <InstagramWrapper>
                            <Link href={T.socials.instagram}>
                                <Icons.Instagram/>
                            </Link>
                        </InstagramWrapper>
                    </LinksWrapper>
                    <Language footer={footer}>
                        {extractedLang}
                    </Language>
                    <Icons.Language
                        color={footer ? colors.typography.regular : undefined}
                        onClick={event => setPopperState({
                            anchor: event.target as HTMLElement,
                            actions: [
                                {
                                    name: 'UA',
                                    flag: flags.ukr,
                                    onClick: () => {
                                        setLanguage(Languages.ua_UA)
                                        setPopperState({
                                            anchor: undefined
                                        })
                                    }
                                },
                                {
                                    name: 'RUS',
                                    flag: flags.ros,
                                    onClick: () => {
                                        setLanguage(Languages.ru_RU)
                                        setPopperState({
                                            anchor: undefined
                                        })
                                    }
                                },
                                {
                                    name: 'ENG',
                                    flag: flags.eng,
                                    onClick: () => {
                                        setLanguage(Languages.en_GB)
                                        setPopperState({
                                            anchor: undefined
                                        })
                                    }
                                }
                            ]
                        })}
                    />
                </LanguagesWrapper>
            )}
        </Container>
    )
}

const Container = styled.div`
  width: 100%;
  max-width: 1000px;
  display: flex;
  height: 100px;
  position: relative;
`

const LogoWrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
`

const LogoText = styled(Typography)<HeaderProps>`
  color: ${props => props.footer ? colors.typography.regular : colors.white};
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 4px;
  letter-spacing: 0.5px;
`

const MiddleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-left: 90px;
  @media (max-width: 978px) {
    display: none;
  }
`

const Navigate = styled(Typography)<HeaderProps>`
  color: ${props => props.footer ? colors.typography.regular : colors.white};
  margin: 0 30px;
  text-transform: capitalize;
  cursor: pointer;
  z-index: 1;
`

const LanguagesWrapper = styled.div`
  display: flex;
  align-items: center;
  z-index: 1;
  margin-left: auto;
`

const Language = styled(Typography)<HeaderProps>`
  text-transform: capitalize;
  color: ${props => props.footer ? colors.typography.regular : colors.white};
  margin-right: 10px;
`

const LinksWrapper = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
`

const InstagramWrapper = styled.div`
  margin-left: 30px;
`
