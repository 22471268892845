import React from 'react'
import styled from 'styled-components'
import { colors } from 'lib/styles'
import { constants } from 'common'

type BodyProps = {
    id: string
}

export const Body: React.FunctionComponent<BodyProps> = ({
    children,
    id
}) => (
    <Container id={id}>
        <Wrapper>
            {children}
        </Wrapper>
    </Container>
)

const Container = styled.div`
  background-color: ${colors.darkerPrimary};
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
`

const Wrapper = styled.div`
  max-width: ${constants.maxBodyWidth}px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 978px) {
    padding: 0 20px;
  }
`
