import React from 'react'
import { GeneralIconProps, Icon } from 'lib/components'

type MouseProps = GeneralIconProps & {
    id: string
}

export const Mouse: React.FunctionComponent<MouseProps> = ({ color, id, onClick }) => (
    <Icon
        fill="none"
        height={69}
        viewBox="0 0 24 69"
        onClick={onClick}
    >
        <path d="M5 10.8C5 8.18126 5 6.87188 5.58944 5.91001C5.91926 5.37178 6.37178 4.91926 6.91001 4.58944C7.87188 4 9.18126 4 11.8 4L12.2 4C14.8187 4 16.1281 4 17.09 4.58944C17.6282 4.91926 18.0807 5.37179 18.4106 5.91001C19 6.87189 19 8.18126 19 10.8L19 14C19 14.9288 19 15.3933 18.9487 15.7832C18.5942 18.4756 16.4756 20.5942 13.7832 20.9487C13.3933 21 12.9288 21 12 21V21C11.0712 21 10.6067 21 10.2168 20.9487C7.52444 20.5942 5.40579 18.4756 5.05133 15.7832C5 15.3933 5 14.9288 5 14L5 10.8Z" stroke={color} strokeWidth="2" strokeLinejoin="round"/>
        <path d="M12 4L12 9" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 69L12 39" stroke={`url(#${id})`} strokeWidth="2"/>
        <defs>
            <linearGradient id={id} x1="11.5" y1="69" x2="11.5" y2="39" gradientUnits="userSpaceOnUse">
                <stop stopColor={color} stopOpacity="0"/>
                <stop offset="1" stopColor={color}/>
            </linearGradient>
        </defs>
    </Icon>
)
