import React from 'react'
import { colors } from '../styles'

export type GeneralIconProps = {
    color?: string,
    width?: number,
    height?: number,
    fill?: string,
    viewBox?: string,
    onClick?(event: React.MouseEvent): void
}

export const Icon: React.FunctionComponent<GeneralIconProps> = ({
    color = colors.white,
    children,
    width,
    height,
    fill,
    viewBox,
    onClick
}) => (
    <svg
        onClick={onClick}
        width={width || 24}
        fill={fill || color}
        height={height || 24}
        viewBox={viewBox || '0 0 24 24'}
        style={{
            cursor: onClick ? 'pointer' : undefined
        }}
    >
        {children}
    </svg>
)
