import React from 'react'
import styled from 'styled-components'
import { Header } from '../components/main'

export const Footer: React.FunctionComponent = () => (
    <Container>
        <Header footer/>
    </Container>
)

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  @media (max-width: 978px) {
    padding: 0 20px;
  }
`
