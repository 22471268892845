import { useTranslations } from 'lib/hooks'
import { colors, stylesHelpers } from 'lib/styles'

export const useGetPros = () => {
    const T = useTranslations()

    return [
        {
            title: T.pages.main.pros.first,
            color: colors.primary
        },
        {
            title: T.pages.main.pros.second,
            color: stylesHelpers.hexToRGBA(colors.primary, 0.8)
        },
        {
            title: T.pages.main.pros.third,
            color: stylesHelpers.hexToRGBA(colors.primary, 0.6)
        },
        {
            title: T.pages.main.pros.fourth,
            color: stylesHelpers.hexToRGBA(colors.primary, 0.6)
        },
        {
            title: T.pages.main.pros.fifth,
            color: stylesHelpers.hexToRGBA(colors.primary, 0.8)
        },
        {
            title: T.pages.main.pros.sixth,
            color: colors.primary
        },
        {
            title: T.pages.main.pros.seventh,
            color: colors.primary
        },
        {
            title: T.pages.main.pros.eight,
            color: colors.success
        },
        {
            title: T.pages.main.pros.ninth,
            color: colors.primary
        },
        {
            title: T.pages.main.pros.tenth,
            color: stylesHelpers.hexToRGBA(colors.primary, 0.8)
        },
        {
            title: T.pages.main.pros.eleventh,
            color: colors.success
        },
        {
            title: T.pages.main.pros.twelfth,
            color: colors.primary
        }
    ]
}
