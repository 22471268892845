import { useCallback } from 'react'

export const useNavigateScroll = () => useCallback((id: string) => {
    const el = document.getElementById(id)

    if (el) {
        window.scroll({
            behavior: 'smooth',
            top: el.offsetTop
        })
    }
}, [])
