import React from 'react'
import styled from 'styled-components'
import { ReactSmartScroller } from 'react-smart-scroller'
import { constants } from 'common'
import { Icons, images } from 'assets'
import { colors } from 'lib/styles'
import { Pagination, Typography } from 'lib/components'
import { useNavigateScroll, useResize, useTranslations } from 'lib/hooks'
import { OnePros, useGetPros } from '../components/pros'

export const Pros: React.FunctionComponent = () => {
    const T = useTranslations()
    const pros = useGetPros()
    const { window: { width } } = useResize()
    const firstHalf = pros.slice(0, pros.length / 2)
    const secondHalf = pros.slice(pros.length / 2, pros.length)
    const onNavigate = useNavigateScroll()
    const phone = width < 978

    return (
        <Container id={constants.prosId}>
            <Title>
                {T.pages.main.pros.title}
            </Title>
            <Wrapper>
                {!phone && (
                    <ProsWrapper>
                        {firstHalf.map((item, index) => (
                            <OnePros
                                left
                                {...item}
                                key={index}
                                rowIndex={index + 1}
                            />
                        ))}
                    </ProsWrapper>
                )}
                <Map
                    alt="stateMap"
                    src={images.stateMap}
                />
                {!phone && (
                    <ProsWrapper>
                        {secondHalf.map((item, index) => (
                            <OnePros
                                {...item}
                                key={index}
                                rowIndex={index + 7}
                            />
                        ))}
                    </ProsWrapper>
                )}
                {phone && (
                    <ReactSmartScroller
                        pagination
                        paginationConfig={{
                            draggable: true,
                            minOffsetToChangeSlide: 10
                        }}
                        renderPagination={options => (
                            <Pagination
                                options={options}
                            />
                        )}
                    >
                        <ProsWrapper>
                            {firstHalf.map((item, index) => (
                                <OnePros
                                    left
                                    {...item}
                                    key={index}
                                    rowIndex={index + 1}
                                />
                            ))}
                        </ProsWrapper>
                        <ProsWrapper>
                            {secondHalf.map((item, index) => (
                                <OnePros
                                    {...item}
                                    key={index}
                                    rowIndex={index + 7}
                                />
                            ))}
                        </ProsWrapper>
                    </ReactSmartScroller>
                )}
            </Wrapper>
            {!phone && (
                <Icons.Mouse
                    id="prosId"
                    color={colors.button}
                    onClick={() => onNavigate(constants.aboutMeId)}
                />
            )}
        </Container>
    )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 90px 0;
  position: relative;
  border-top: 1px solid ${colors.typography.lightRegular};
  @media (max-width: 1200px) {
    padding: 70px 20px;
  }
  @media (max-width: 978px) {
    padding: 40px 20px;
  }
`

const Wrapper = styled.div`
  display: flex;
  background-color: ${colors.white};
  width: 100%;
  justify-content: center;
  @media (max-width: 1200px) {
    justify-content: space-between;
  }
  @media (max-width: 978px) {
    flex-direction: column;
  }
`

const ProsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 10px;
  box-sizing: border-box;
`

const Map = styled.img`
  width: 30%;
  object-fit: contain;
  margin: 0 50px;
  @media (max-width: 978px) {
    width: 100%;
    position: absolute;
    margin: 0;
    left: 0;
    max-width: 600px;
  }
`

const Title = styled(Typography)`
  margin-bottom: 40px;
  font-size: 36px;
  line-height: 22px;
  font-weight: bold;
  color: ${colors.black};
  @media (max-width: 978px) {
    font-size: 22px;
    line-height: 20px;
  }
`
