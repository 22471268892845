import { translationStore } from './translationStore'
import { usePopperState } from './usePopperState'

export const stores = [
    translationStore,
    usePopperState
]

export {
    translationStore,
    usePopperState
}
