import React from 'react'
import styled from 'styled-components'
import { images } from 'assets'
import { constants } from 'common'
import { colors } from 'lib/styles'

export const Body: React.FunctionComponent = ({ children }) => (
    <Container>
        <Wrapper>
            {children}
        </Wrapper>
        <MapWrapper>
            <Map src={images.worldMap}/>
        </MapWrapper>
    </Container>
)

const Container = styled.div`
  background-color: ${colors.primary};
  height: 100vh;
  display: flex;
  justify-content: center;
  position: relative;
`

const Wrapper = styled.div`
  width: ${constants.maxBodyWidth}px;
  padding-bottom: 60px;
  z-index: 1;
  @media (max-width: 1200px) {
    width: 900px;
  }
  @media (max-width: 978px) {
    width: 100%;
    padding: 0 20px;
  }
`

const MapWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Map = styled.img`
  object-fit: cover;
  position: absolute;
  top: 15%;
  width: 80%;
`
