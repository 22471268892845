import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Icons } from 'assets'
import { colors } from 'lib/styles'
import { Pagination, Typography } from 'lib/components'
import { useResize, useTranslations } from 'lib/hooks'
import { Tab as TabModel } from './types'
import { ReactSmartScroller } from 'react-smart-scroller'

type TabProps = TabModel & {
    isActive: boolean,
    rowIndex: number,
    anyActive: boolean,
    first: boolean,
    last: boolean,
    onClick(value: number): void
}

type TabStyleProps = {
    isActive?: boolean,
    anyActive?: boolean,
    first?: boolean,
    last?: boolean,
    motto?: boolean,
    customHeight?: number
}

type OverlayProps = {
    customHeight?: number
}

export const Tab: React.FunctionComponent<TabProps> = ({
    icon,
    title,
    texts,
    isActive,
    onClick,
    rowIndex,
    anyActive,
    first,
    last,
    expanded
}) => {
    const { window: { width } } = useResize()
    const { item: { height }, ref, refresh } = useResize()
    const T = useTranslations()
    const color = isActive
        ? colors.white
        : anyActive
            ? colors.gray.mediumGray
            : colors.button
    const phone = width < 978

    useEffect(() => {
        refresh()
    }, [isActive])

    return (
        <Container
            isActive={isActive}
            anyActive={anyActive}
            onClick={() => onClick(rowIndex)}
        >
            <Header
                isActive={isActive}
                anyActive={anyActive}
            >
                {icon({
                    color,
                    width: 30,
                    height: 30
                })}
                <Title>
                    {title}
                </Title>
            </Header>
            <Body
                last={last}
                first={first}
                isActive={isActive}
                anyActive={anyActive}
                customHeight={height}
            >
                {!anyActive && texts.map((item, index) => {
                    if (item === '') {
                        return
                    }

                    return (
                        <Text key={`${item}-${index}`}>
                            {item}
                        </Text>
                    )
                })}
                {isActive && !phone && (
                    <Overlay ref={ref}>
                        <OverlayEl first>
                            {texts.map((item, index) => {
                                if (item === '') {
                                    return
                                }

                                return (
                                    <Text key={`${item}-${index}`}>
                                        {item}
                                    </Text>
                                )
                            })}
                        </OverlayEl>
                        <OverlayEl motto>
                            {expanded.middle}
                        </OverlayEl>
                        <OverlayEl last>
                            {expanded.texts.map((item, index) => {
                                if (item === '') {
                                    return
                                }

                                return (
                                    <Text key={`${item}-${index}`}>
                                        {item}
                                    </Text>
                                )
                            })}
                        </OverlayEl>
                    </Overlay>
                )}
                {phone && isActive && (
                    <Overlay ref={ref}>
                        <ReactSmartScroller
                            pagination
                            paginationConfig={{
                                draggable: true,
                                minOffsetToChangeSlide: 10
                            }}
                            renderPagination={options => (
                                <Pagination
                                    options={options}
                                    dotColor={colors.white}
                                />
                            )}
                        >
                            <OverlayEl first>
                                {texts.map((item, index) => {
                                    if (item === '') {
                                        return
                                    }

                                    return (
                                        <Text key={`${item}-${index}`}>
                                            {item}
                                        </Text>
                                    )
                                })}
                            </OverlayEl>
                            <OverlayEl motto>
                                {expanded.middle}
                            </OverlayEl>
                            <OverlayEl last>
                                {expanded.texts.map((item, index) => {
                                    if (item === '') {
                                        return
                                    }

                                    return (
                                        <Text key={`${item}-${index}`}>
                                            {item}
                                        </Text>
                                    )
                                })}
                            </OverlayEl>
                        </ReactSmartScroller>
                    </Overlay>
                )}
            </Body>
            {!anyActive && (
                <IconWrapper
                    last={last}
                    first={first}
                    anyActive={anyActive}
                >
                    <Icons.ArrowRight color={colors.primary}/>
                    <TextWrapper>
                        {T.common.seeMore}
                    </TextWrapper>
                </IconWrapper>
            )}
            {isActive && (
                <IconWrapper
                    last={last}
                    first={first}
                    isActive={isActive}
                    anyActive={anyActive}
                >
                    <Icons.Close color={colors.button}/>
                </IconWrapper>
            )}
            {!anyActive && (
                <NumberWrapper>
                    <BigNumber>
                        {rowIndex}
                    </BigNumber>
                </NumberWrapper>
            )}
            {isActive && (
                <BigNumber isActive>
                    {rowIndex}
                </BigNumber>
            )}
        </Container>
    )
}

const Container = styled.div<TabStyleProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`

const Header = styled.div<TabStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 50px;
  border-radius: 16px 16px 0 0;
  background-color: ${props => props.isActive ? colors.primary : undefined};
  div {
    color: ${props => props.isActive
        ? colors.white
        : props.anyActive
            ? colors.gray.mediumGray
            : colors.typography.regular
};
  }
  @media (max-width: 978px) {
    padding: 15px 25px;
  }
`

const Title = styled(Typography)`
  margin-left: 10px;
  font-size: 24px;
  line-height: 30px;
  @media (max-width: 978px) {
    display: none;
  }
`

const Body = styled.div<TabStyleProps>`
  background-color: ${props => (props.isActive || props.anyActive) ? colors.primary : undefined};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 0 50px;
  div {
    color: ${props => (props.isActive || props.anyActive) ? colors.white : colors.typography.regular};
  }
  min-height: ${props => props.customHeight || 150}px;
  @media (max-width: 978px) {
    padding: 0 25px;
    min-height: ${props => props.customHeight || 200}px;
  }
`

const Text = styled(Typography)`
  font-size: 14px;
  line-height: 22px;
  width: 100%;
  margin-bottom: 5px;
  ::before {
    content: "-";
    margin-right: 5px;
  }
`

const IconWrapper = styled.div<TabStyleProps>`
  margin-top: auto;
  display: flex;
  background-color: ${props => props.isActive
        ? undefined
        : props.anyActive
            ? colors.primary
            : undefined
};
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: ${props => props.first ? 16 : 0}px;
  border-bottom-right-radius: ${props => props.last ? 16 : 0}px;
  position: ${props => props.isActive ? 'absolute' : 'static'};
  bottom: ${props => props.isActive ? 0 : undefined};
  left: calc(${props => props.isActive ? 50 : undefined}% - 62px);
  z-index: ${props => props.isActive ? 1 : 0 };
  cursor: ${props => props.isActive ? 'pointer' : 'initial' };
  color: ${colors.primary};
  text-transform: capitalize;
  align-items: center;
  padding: 30px 50px 30px 50px;
  @media (max-width: 978px) {
    display: none;
    padding: 15px 25px 15px 25px;
  }
`

const NumberWrapper = styled.div`
  width: 100%;
  position: relative;
`

const BigNumber = styled.div<TabStyleProps>`
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 140px;
  font-weight: bold;
  color: ${props => props.isActive ? `rgba(0, 0, 0, 0.1)` : colors.background.custom};
  line-height: 100px;
  z-index: 10;
`

const Overlay = styled.div<OverlayProps>`
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 30px 0;
  justify-content: space-between;
  min-height: ${props => props.customHeight || 250}px;
  @media (max-width: 978px) {
    padding: 0;
    min-height: ${props => props.customHeight || 200}px;
  }
`

const OverlayEl = styled.div<TabStyleProps>`
  padding: 0 50px;
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-style: ${props => props.motto ? 'italic' : 'normal'};
  text-align: ${props => props.motto ? 'center' : 'left'};
  @media (max-width: 978px) {
    padding: 0 25px;
  }
`

const TextWrapper = styled.span`
  margin-left: 10px;
`
