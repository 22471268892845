import { useEffect, useState } from 'react'
import { LocalStorageKeys } from 'common'
import { Dictionary } from 'lib/types'
import { localStorageUtils } from 'lib/utils'
import { languages, Languages } from 'lib/locale'

/* eslint-disable react-hooks/rules-of-hooks */

export const translationStore = () => {
    const [ language, setLanguage ] = useState(localStorageUtils.getLocalStorage(LocalStorageKeys.LanguageTag) as Languages || Languages.en_GB)
    const [ mounted, setMounted ] = useState(false)

    useEffect(() => {
        const localStorageKey = localStorageUtils.getLocalStorage(LocalStorageKeys.LanguageTag)

        if (!localStorageKey) {
            localStorageUtils.setLocalStorage(LocalStorageKeys.LanguageTag, Languages.en_GB)
        }

        setMounted(true)
    }, [])

    useEffect(() => {
        const localStorageKey = localStorageUtils.getLocalStorage(LocalStorageKeys.LanguageTag)

        if (mounted && language !== localStorageKey) {
            localStorageUtils.setLocalStorage(LocalStorageKeys.LanguageTag, language)
            window.location.reload()
        }
    }, [language, mounted])

    return {
        languageState: {
            stateLanguage: language,
            language: languages[language] as Dictionary
        },
        languageActions: {
            setLanguage
        }
    }
}

/* eslint-enable react-hooks/rules-of-hooks */
