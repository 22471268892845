import React from 'react'
import styled from 'styled-components'
import { constants } from 'common'
import { Icons, images } from 'assets'
import { colors } from 'lib/styles'
import { Typography, Button } from 'lib/components'
import { useNavigateScroll, useTranslations } from 'lib/hooks'

export const Title: React.FunctionComponent = () => {
    const T = useTranslations()
    const onNavigate = useNavigateScroll()

    return (
        <Container>
            <Main>
                {T.pages.main.first.title}
                <Person
                    alt="person"
                    src={images.person}
                />
            </Main>
            <SubTitle>
                {T.pages.main.first.subTitle}
            </SubTitle>
            <Link
                target="_blank"
                href="https://t.me/relocat_pl"
            >
                <CustomButton>
                    <IconWrapper>
                        <Icons.Telegram/>
                    </IconWrapper>
                    {T.pages.main.first.button}
                </CustomButton>
            </Link>
            <Technology src={images.icons}/>
            <Scroll>
                <Icons.Mouse
                    id="serviceId"
                    color={colors.white}
                    onClick={() => onNavigate(constants.servicesId)}
                />
            </Scroll>
        </Container>
    )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  @media (max-width: 978px) {
    left: 20px;
    right: 20px;
    width: auto;
  }
`

const Main = styled(Typography)`
  white-space: pre-line;
  color: ${colors.white};
  font-size: 50px;
  text-align: center;
  font-weight: bold;
  line-height: 60px;
  @media (max-width: 978px) {
    font-size: 25px;
    line-height: 30px;
  }
`

const SubTitle = styled(Typography)`
  color: ${colors.white};
  font-size: 32px;
  text-align: center;
  margin-top: 30px;
  @media (max-width: 978px) {
    font-size: 16px;
    white-space: pre;
  }
`

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CustomButton = styled(Button)`
  margin-top: 60px;
  cursor: pointer;
`

const Technology = styled.img`
  margin-top: 70px;
  width: 50%;
`

const Scroll = styled.div`
  position: absolute;
  bottom: 40px;
  cursor: pointer;
`

const Person = styled.img`
  height: 50px;
  @media (max-width: 978px) {
    height: 25px;
  }
`

const Link = styled.a`
  outline: none;
  text-decoration: none;
`
