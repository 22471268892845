import { useCallback, useState } from 'react'

type EmailProps = {
    name?: string,
    email: string,
    contactNumber?: string,
    message?: string
}

type EmailResponse = {
    message?: string,
    error?: boolean
}

export const onEmailSend = () => {
    const [ isFetching, setIsFetching ] = useState(false)
    const [ response, setResponse ] = useState<EmailResponse>({})

    const sendEmail = useCallback(({
        contactNumber,
        email,
        name,
        message
    }: EmailProps) => {
        setIsFetching(true)

        const xhr = new XMLHttpRequest()
        const body = new FormData()

        body.append('email', email)

        if (contactNumber) {
            body.append('contactNumber', contactNumber)
        }

        if (name) {
            body.append('name', name)
        }

        if (message) {
            body.append('message', message)
        }

        xhr.open('POST', 'mail.php')
        xhr.onload = () => {
            setIsFetching(false)

            if (xhr.response === '') {
                return null
            }

            const response = JSON.parse(xhr.response)

            if (response.status === 400) {
                setResponse(prevState => ({
                    ...prevState,
                    error: true
                }))
            }

            if (response.status === 200) {
                setResponse(prevState => ({
                    ...prevState,
                    error: false
                }))
            }

            return setResponse(prevState => ({
                ...prevState,
                message: response.message
            }))
        }
        xhr.send(body)
    }, [])

    return {
        sendEmail,
        isFetching,
        response
    }
}
