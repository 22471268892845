import { Dictionary } from '../types'

export const en_GB: Dictionary = {
    common: {
        logo: 'relocat',
        seeMore: 'see more'
    },
    socials: {
        instagram: 'https://instagram.com/relocat.pl?utm_medium=copy_link',
        linkedIn: ''
    },
    pages: {
        main: {
            first: {
                about: 'About Us',
                contact: 'Contacts',
                services: 'Services',
                pros: 'Pros',
                button: 'Subscribe to our channel in Telegram',
                title: 'Relocation to Poland for IT specialists',
                subTitle: 'We make the relocation process smoother for You'
            },
            contact: {
                howToContact: 'Contact Us',
                name: 'Name',
                email: 'E-mail',
                data: 'Date and time of calling',
                send: 'Send',
                contact: 'Contact',
                info: 'If You have any questions fee free to contact us.',
                number: '+48 883 516 178',
                site: 'contact@relocat.pl',
                instagram: 'relocate',
                message: 'Message',
                subscribe: 'Subscribe',
                mail: '📨',
                subscribeText: 'Sign up for our newsletter with useful information'
            },
            about: {
                aboutMe: 'About Us',
                text: 'We live in a world with conditional borders, now you can work and study anywhere in the world, you just need desire. Poland is mentally very similar to our countries, so every year many young people decide to relocate here, including a lot of people from Ukraine and Belarus. The choice is quite obvious, first of all this is life in the European Union with the opportunity to obtain citizenship and get retirement in the future. We prefer to have better living conditions, no corruption and the law is equal for everyone. In addition, the housing stock in Poland is quite actively updating with new homes, so finding comfortable and modern apartment is easy and affordable. Prices for clothes and food will also positively surprise you. It is worth to mention a direct border to Ukraine and Belarus, which makes the journey to parents, relatives or friends quite fast. Traveling by plane from Warsaw to Kyiv takes only 1.5 hour. Our ReloCat agency helps IT professionals to easily move and find a job in Poland. You will receive legal documents for living in the European Union Country, well-paid work, social insurance.',
                firstCheck: 'support for the adaptation period',
                secondCheck: 'fast organization process',
                thirdCheck: 'professional service',
                button: 'Subscribe to our channel in Telegram '
            },
            pros: {
                first: 'Living in the European Union',
                second: 'Safe country to live',
                third: 'Cultural and language closeness',
                fourth: 'Reliable health care',
                fifth: 'Stable economic environment',
                sixth: 'Low level of corruption',
                seventh: 'High quality education',
                eight: 'New housing market',
                ninth: 'Developed infrastructure',
                tenth: 'Great flight communication system',
                eleventh: 'Afforable prices for clothes and food',
                twelfth: 'One of the cleanest countries in Europe',
                title: 'Pros of relocation to Poland'
            },
            info: {
                first: 'If you plan to move to a country with a good infrastructure, low levels of corruption, a secure and predictable future, we offer to move to Poland. Relocation to another country, both on a temporary basis and for permanent residence, requires from us iron discipline, full involvement and painstaking planning. We will help make the relocation process and finding a job as enjoyable as possible.',
                second: '',
                title: 'Our services',
                third: '',
                texts: {
                    first: {
                        title: 'Documents',
                        sub1: 'Documents support through relocation process',
                        sub2: 'Legalisation and adaptation',
                        sub3: 'Insurance',
                        extend: {
                            middle: 'We help to prepare all the necessary documents to save your time and mental health',
                            first: 'Bank accounts opening',
                            second: 'PESEL - national indentification number',
                            third: 'Open question consultations'
                        }
                    },
                    second: {
                        title: 'Accomodation',
                        sub1: 'Finding accommodation',
                        sub2: 'Buy a property',
                        extend: {
                            middle: 'Do You want to find an apartment quickly, affordable and in a good area, we are ready to help.',
                            first: 'Temporary resident registration',
                            second: 'Consultations',
                            third: 'Rent accommodation'
                        }
                    },
                    third: {
                        title: 'Careers',
                        sub1: 'Find a well paid job',
                        sub2: 'Job search consultations',
                        extend: {
                            middle: 'Looking for a job or looking for a company that is not on the Poland Business Harbor program list, want to work on Your own contact us, we will consult and support You in those topics.',
                            first: 'Accounting services',
                            second: 'Law services',
                            third: 'Tax consultations'
                        }
                    }
                }
            }
        }
    }
}
