import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from 'lib/styles'
import { onEmailSend, useTranslations } from 'lib/hooks'
import { Button, Input, Typography, Loader } from 'lib/components'

type ResponseStyle = {
    error: boolean
}

export const Footer: React.FunctionComponent = () => {
    const T = useTranslations()
    const [ email, setEmail ] = useState('')
    const { response, sendEmail, isFetching } = onEmailSend()

    return (
        <Container>
            <Wrapper>
                <Left>
                    <Icon>
                        {T.pages.main.contact.mail}
                    </Icon>
                    <Text>
                        {T.pages.main.contact.subscribeText}
                    </Text>
                </Left>
                <Right>
                    <Input
                        onChange={setEmail}
                        label={T.pages.main.contact.email}
                    />
                    <Subscribe
                        onClick={() => sendEmail({
                            email
                        })}
                    >
                        {!isFetching && T.pages.main.contact.subscribe}
                        {isFetching && (
                            <Loader/>
                        )}
                    </Subscribe>
                </Right>
            </Wrapper>
            {response.message && (
                <Response error={Boolean(response.error)}>
                    {response.message}
                </Response>
            )}
        </Container>
    )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media (max-width: 978px) {
    flex-direction: column;
  }
`

const Subscribe = styled(Button)`
  border-radius: 14px;
  background-color: ${colors.button};
  margin-left: 15px;
`

const Left = styled.div`
  display: flex;
  margin-right: auto;
  align-items: center;
  @media (max-width: 978px) {
    margin-right: unset;
  }
`

const Icon = styled.div`
  width: 50px;
  height: 50px;
  background-color: ${colors.button};
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1em;
  margin-right: 15px;
`

const Text = styled(Typography)`
  font-weight: bold;
  display: flex;
  flex: 1;
`

const Response = styled(Typography)<ResponseStyle>`
  color: ${props => props.error ? colors.red : colors.success};
  margin-top: 10px;
  margin-left: auto;
`

const Right = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 978px) {
    margin-top: 20px;
  }
`
