import React from 'react'
import styled from 'styled-components'
import { colors } from '../styles'

type InputProps = {
    color?: string,
    label?: string,
    multiline?: number,
    number?: boolean,
    onChange(value: string): void
}

export const Input: React.FunctionComponent<InputProps> = ({
    onChange,
    label,
    multiline,
    number
}) => (
    <Wrapper>
        {!multiline && (
            <ProperInput
                placeholder={label}
                type={number ? 'number' : 'text'}
                onChange={event => onChange(event.target.value)}
            />
        )}
        {multiline && (
            <ProperArea
                rows={multiline}
                placeholder={label}
                onChange={event => onChange(event.target.value)}
            />
        )}
    </Wrapper>
)

const Wrapper = styled.div`
  display: flex;
`

const ProperInput = styled.input`
  text-decoration: none;
  font-size: 18px;
  color: ${colors.typography.regular};
  padding: 20px 15px;
  outline: none;
  width: 100%;
  border-radius: 16px;
  border: 2px solid ${props => props.color || colors.gray.lightGray};
`

const ProperArea = styled.textarea`
  text-decoration: none;
  font-size: 18px;
  color: ${colors.typography.regular};
  padding: 20px 15px;
  outline: none;
  width: 100%;
  border-radius: 16px;
  border: 2px solid ${props => props.color || colors.gray.lightGray};
  resize: none;
  font-family: Helvetica, serif;
`
