import { graphql, useStaticQuery } from 'gatsby'

export const getSiteMetadata = () => useStaticQuery(graphql`
    query {
        site {
            siteMetadata {
                title
                description
                author
                keywords
                canonical
            }
        }
    }
`)
