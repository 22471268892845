import React from 'react'
import styled from 'styled-components'
import { Icons } from 'assets'
import { constants } from 'common'
import { colors } from 'lib/styles'
import { TabTable, Typography } from 'lib/components'
import { useNavigateScroll, useTranslations } from 'lib/hooks'
import { useTabs } from '../components/info'

type TextStyle = {
    color?: string
}

export const Info: React.FunctionComponent = () => {
    const T = useTranslations()
    const tabs = useTabs()
    const onNavigate = useNavigateScroll()

    return (
        <Container id={constants.servicesId}>
            <TabsWrapper>
                <TabsContainer>
                    <Title>
                        {T.pages.main.info.title}
                    </Title>
                    <TabTable tabs={tabs}/>
                </TabsContainer>
            </TabsWrapper>
            <Wrapper>
                <TextWrapper>
                    <Text>
                        {T.pages.main.info.first}
                    </Text>
                    <Text color={colors.primary}>
                        {T.pages.main.info.second}
                    </Text>
                    <Text>
                        {T.pages.main.info.third}
                    </Text>
                </TextWrapper>
                <Icons.Mouse
                    id="infoId"
                    color={colors.button}
                    onClick={() => onNavigate(constants.prosId)}
                />
            </Wrapper>
        </Container>
    )
}

const Container = styled.div`
  background-color: ${colors.white};
  width: 100%;
`

const Wrapper = styled.div`
  max-width: ${constants.maxBodyWidth}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 60px auto;
  @media (max-width: 1200px) {
    max-width: 900px;
  }
  @media (max-width: 978px) {
    padding: 0 20px;
    margin: 20px auto;
  }
`

const TextWrapper = styled.div`
  margin-bottom: 30px;
  max-width: 700px;
  text-align: center;
`

const Text = styled.span<TextStyle>`
  color: ${props => props.color || colors.typography.lightRegular};
  margin: 0 5px;
  font-size: 18px;
  line-height: 34px;
  @media (max-width: 978px) {
    font-size: 14px;
    line-height: 28px;
  }
`

const Title = styled(Typography)`
  font-size: 36px;
  margin-bottom: 60px;
`

const TabsWrapper = styled.div`
  background-color: ${colors.background.custom};
  width: 100%;
  padding: 60px 0;
  display: flex;
  justify-content: center;
`

const TabsContainer = styled.div`
  max-width: ${constants.maxBodyWidth}px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  @media (max-width: 1200px) {
    max-width: 900px;
    padding: 0 20px;
  }
`
