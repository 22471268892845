import React from 'react'
import styled from 'styled-components'
import { Icons } from 'assets'
import { colors } from 'lib/styles'
import { useTranslations } from 'lib/hooks'
import { Typography, Button } from 'lib/components'

export const LeftSection: React.FunctionComponent = () => {
    const T = useTranslations()

    return (
        <div>
            <Section>
                {T.pages.main.contact.contact}
            </Section>
            <Title>
                {T.pages.main.contact.howToContact}
            </Title>
            <SubTitle>
                {T.pages.main.contact.info}
            </SubTitle>
            <CustomLink
                target="_blank"
                href="https://t.me/relocat_pl"
            >
                <CustomButton>
                    <Icons.Telegram/>
                    <CustomText>
                        {T.pages.main.contact.number}
                    </CustomText>
                </CustomButton>
            </CustomLink>
            <CustomButton>
                <Icons.Email/>
                <CustomText>
                    {T.pages.main.contact.site}
                </CustomText>
            </CustomButton>
            <CustomLink
                target="_blank"
                href={T.socials.instagram}
            >
                <CustomButton>
                    <Icons.Instagram/>
                    <CustomText>
                        {T.pages.main.contact.instagram}
                    </CustomText>
                </CustomButton>
            </CustomLink>
        </div>
    )
}

const Section = styled(Typography)`
  font-size: 18px;
  color: ${colors.button};
  text-transform: uppercase;
  margin-bottom: 15px;
  font-weight: bold;
  line-height: 18px;
`

const Title = styled(Typography)`
  line-height: 60px;
  font-size: 36px;
  font-weight: bold;
  color: ${colors.white};
  margin-bottom: 15px;
  @media (max-width: 978px) {
    line-height: unset;
    font-size: 30px;
  }
`

const SubTitle = styled(Typography)`
  font-size: 24px;
  line-height: 28px;
  color: ${colors.white};
  margin-bottom: 90px;
  @media (max-width: 978px) {
    line-height: 24px;
    margin-bottom: 50px;
  }
`

const CustomButton = styled(Button)`
  background-color: transparent;
  border: 2px solid ${colors.gray.border};
  color: ${colors.gray.border};
  border-radius: 14px;
  margin: 20px 0;
  padding: 15px;
`

const CustomText = styled(Typography)`
  margin-left: 20px;
  color: ${colors.white};
  margin-right: 100px;
`

const CustomLink = styled.a`
  outline: none;
  text-decoration: none;
`
