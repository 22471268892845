import React from 'react'
import { Icons } from 'assets'
import { Tab } from 'lib/components'
import { useTranslations } from 'lib/hooks'

export const useTabs = (): Array<Tab> => {
    const T = useTranslations()
    const texts = T.pages.main.info.texts

    return [
        {
            icon: props => <Icons.File {...props}/>,
            title: texts.first.title,
            texts: [texts.first.sub1, texts.first.sub2, texts.first.sub3],
            expanded: {
                middle: texts.first.extend.middle,
                texts: [texts.first.extend.first, texts.first.extend.second, texts.first.extend.third]
            }
        },
        {
            icon: props => <Icons.Home {...props}/>,
            title: texts.second.title,
            texts: [texts.second.sub1, texts.second.sub2, ''],
            expanded: {
                middle: texts.second.extend.middle,
                texts: [texts.second.extend.first, texts.second.extend.second, texts.second.extend.third]
            }
        },
        {
            icon: props => <Icons.Laptop {...props}/>,
            title: texts.third.title,
            texts: [texts.third.sub1, texts.third.sub2, ''],
            expanded: {
                middle: texts.third.extend.middle,
                texts: [texts.third.extend.first, texts.third.extend.second, texts.third.extend.third]
            }
        }
    ]
}
