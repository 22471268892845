import React from 'react'
import { Layout, Seo, Popper } from 'lib/components'
import { First, Footer, Contact, About, Info, Pros } from './sections'

export const Main: React.FunctionComponent = () => (
    <Layout>
        <Seo/>
        <First/>
        <Info/>
        <Pros/>
        <About/>
        <Contact/>
        <Footer/>
        <Popper/>
    </Layout>
)
