import React from 'react'
import { colors, stylesHelpers } from 'lib/styles'

export type Stylesheet = {
    // eslint-disable-next-line
    [key: string]: React.CSSProperties | { [key: string]: React.CSSProperties } | ((...props: any) => React.CSSProperties)
}

type MakeStylesProps = {
    colors: typeof colors,
    utils: typeof stylesHelpers,
    borderRadius: number,
    margin: number,
    padding: number
}

export const createStylesheet = <T extends Stylesheet>(styles: T) => styles
export const makeStyles = <T extends Stylesheet>(styles: (props: MakeStylesProps) => T) => {
    const margin = 16
    const borderRadius = 16
    const padding = 16

    return styles({
        borderRadius,
        margin,
        padding,
        utils: stylesHelpers,
        colors
    })
}

/**
 * e.g extracts 20 from '20px'
 */

export const extractCssValue = (value: string | number) => typeof value === 'string'
    ? Number(value.replace('px', ''))
    : value
