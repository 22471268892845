import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from 'lib/styles'
import { onEmailSend, useTranslations } from 'lib/hooks'
import { Paper, Input, Button, Typography, Loader } from 'lib/components'

type FormState = {
    name?: string,
    email: string,
    contactNumber?: string,
    message?: string
}

type ResponseStyle = {
    error: boolean
}

export const Form: React.FunctionComponent = () => {
    const T = useTranslations()
    const [ formState, setFormState ] = useState<FormState>({
        email: ''
    })
    const { response, sendEmail, isFetching } = onEmailSend()

    return (
        <CustomPaper>
            <Input
                label={T.pages.main.contact.name}
                onChange={value => setFormState(prevState => ({
                    ...prevState,
                    name: value
                }))}
            />
            <Margin/>
            <Input
                label={T.pages.main.contact.email}
                onChange={value => setFormState(prevState => ({
                    ...prevState,
                    email: value
                }))}
            />
            <Margin/>
            <Input
                number
                label={T.pages.main.contact.number}
                onChange={value => setFormState(prevState => ({
                    ...prevState,
                    contactNumber: value
                }))}
            />
            <Margin/>
            <Input
                multiline={5}
                label={T.pages.main.contact.message}
                onChange={value => setFormState(prevState => ({
                    ...prevState,
                    message: value
                }))}
            />
            <Margin/>
            <SendButton
                onClick={() => sendEmail(formState)}
            >
                {!isFetching && T.pages.main.contact.send}
                {isFetching && (
                    <Loader/>
                )}
            </SendButton>
            {response.message && (
                <Response error={Boolean(response.error)}>
                    {response.message}
                </Response>
            )}
        </CustomPaper>
    )
}

const CustomPaper = styled(Paper)`
  margin-right: auto;
  background-color: ${colors.gray.lightGray};
  padding: 50px 90px;
  @media (max-width: 978px) {
    padding: 20px;
  }
`

const SendButton = styled(Button)`
  width: 100%;
  border-radius: 14px;
`

const Margin = styled.div`
  margin-bottom: 10px;
`

const Response = styled(Typography)<ResponseStyle>`
  color: ${props => props.error ? colors.red : colors.success};
  margin-top: 10px;
  text-align: center;
`
