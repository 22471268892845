import React from 'react'
import { FormProvider } from '@codegateinc/react-form-builder'
import { Provider } from 'outstated'
import { stores } from 'lib/stores'
import { GlobalStyles } from 'lib/styles'
import { LayoutBody } from './LayoutBody'
import smoothScroll from 'smoothscroll-polyfill'

if (typeof window !== 'undefined') {
    smoothScroll.polyfill()
}

export const Layout: React.FunctionComponent = ({ children }) => (
    <FormProvider>
        <GlobalStyles/>
        <Provider stores={stores}>
            <LayoutBody>
                {children}
            </LayoutBody>
        </Provider>
    </FormProvider>
)
