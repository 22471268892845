import React from 'react'
import { Icons } from 'assets'
import styled, { keyframes } from 'styled-components'

export const Loader: React.FunctionComponent = () => (
    <Container>
        <Icons.Loader/>
    </Container>
)

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  
  to {
    transform: rotate(360deg);
  }
`

const Container = styled.div`
  display: flex;
  animation: ${spin} 2s linear infinite;
`
