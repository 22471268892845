import { Dictionary } from '../types'

export const ua_UA: Dictionary = {
    common: {
        logo: 'relocat',
        seeMore: 'більше'
    },
    socials: {
        instagram: 'https://instagram.com/relocat.pl?utm_medium=copy_link',
        linkedIn: ''
    },
    pages: {
        main: {
            first: {
                about: 'Про нас',
                contact: 'Контакти',
                services: 'Послуги',
                pros: 'Плюси',
                button: 'Підписуйся на наш Telegram канал',
                title: 'Переїзд ІТ спеціалістів в Польщу',
                subTitle: 'Ми зробимо процес переїзду та\nадаптації більш комфортним для Вас'
            },
            contact: {
                howToContact: `Зв'язок з нами`,
                name: `Ім'я`,
                email: 'E-mail',
                data: 'Date and time of calling',
                send: 'Відправити',
                contact: 'Контакт',
                info: 'Якщо у Вас є додаткові питання ми будемо раді відповісти',
                number: '+48 883 516 178',
                site: 'contact@relocat.pl',
                instagram: 'relocate',
                message: 'Повідомлення',
                subscribe: 'Підписуйся',
                mail: '📨',
                subscribeText: 'Підписуйтесь на нашу розсилку з корисною інформацією'
            },
            about: {
                aboutMe: 'Про нас',
                text: 'Ми живемо у світі з умовними кордонами, зараз можна працювати та вчитися у будь-якій точці земної кулі, потрібно тільки бажання. Польща ментально дуже схожа до наших країн, тому з кожним роком сюди переселяється багато молодих людей в тому числі з України й Білорусі. Вибір доволі очевидний, перш за все це життя в Євросоюзі з можливістю отримати громадянство і на старість отримувати пенсію цієї країни. Всі ми хочемо жити у кращих умовах, де є порядок, немає корупції на кожному кроці та працюють закони. Крім того, житловий фонд в Польщі доволі активно поповнюється новими будинками, тому знайти комфортне і сучасне житло легко і доступно. Ціни на одяг і харчування також вас порадують. Варто підмітити безпосередню близькість з Україною і Білоруссю, що робить подорож до батьків, рідних чи друзів досить швидкою. Літаком з Варшави у Київ всього лише 1,5 години. Наше агентство ReloCat допомагає IT спеціалістам з легкістю переїхати та знайти роботу в Польщі. Ви отримаєте легальні документи на проживання в країні Європейського Союзу, високооплачувану роботу, соціальний захист.',
                firstCheck: 'підтримка на період адаптації',
                secondCheck: 'швидка організація',
                thirdCheck: 'якісний сервіс',
                button: 'Підписуйся на наш Telegram канал'
            },
            pros: {
                first: 'Проживання в Євросоюзі',
                second: 'Безпечна країна для проживання',
                third: 'Культурна і мовна близькість',
                fourth: 'Надійна система охорони здоров’я',
                fifth: 'Стабільна економічна ситуація',
                sixth: 'Низький рівень корупції',
                seventh: 'Якісна освіта',
                eight: 'Новий житловий фонд',
                ninth: 'Розвинена інфраструктура',
                tenth: 'Чудове авіасполучення',
                eleventh: 'Приємні ціни на одяг і харчування',
                twelfth: 'Одна з найчистіших країн Європи',
                title: 'Переваги переїзду в Польщу'
            },
            info: {
                first: 'Хочеш переїхати в країну з хорошою інфраструктурою, низьким рівнем корупції, безпечним і передбачуваним майбутнім - ми пропонуємо переїзд у Польщу. Переїзд в іншу країну, як на тимчасовій основі, так і на постійне місце проживання вимагає від нас залізної дисципліни, повного залучення і клопіткого планування. Ми допоможемо зробити процес переїзду і пошуку роботи простим і швидким в реалізації.',
                second: '',
                title: 'Наші послуги',
                third: '',
                texts: {
                    first: {
                        title: 'Документи',
                        sub1: 'Допомога в оформленні документів при переїзді',
                        sub2: 'Легалізація і адаптація',
                        sub3: 'Страховка',
                        extend: {
                            middle: `Ми допомагаємо в оформленні всіх необхідних документів, щоб зекономити ваш час і ментальне здоров'я`,
                            first: 'Відкриття банківського рахунку',
                            second: 'Отримання PESEL - ідентифікаційний номер',
                            third: 'Консультації по загальним питанням'
                        }
                    },
                    second: {
                        title: 'Житло',
                        sub1: 'Пошук житла',
                        sub2: 'Покупка житла',
                        extend: {
                            middle: 'Хочеш знайти житло швидко, доступно і в хорошому районі ми готові в цьому допомогти',
                            first: 'Реєстрація за місцем проживання',
                            second: 'Консультації',
                            third: 'Оренда житла'
                        }
                    },
                    third: {
                        title: 'Кар’єра',
                        sub1: 'Пошук роботи',
                        sub2: 'Допомога з пошуком роботи',
                        extend: {
                            middle: 'Потрібно підібрати роботу, хочеш в компанію, якої немає в списку Poland Business Harbour, цікавить, яку форму працевлаштування вибрати або інші питання, тоді звертайся ми проконсультуємо і допоможемо у всіх питаннях.',
                            first: 'Бухгалтерські послуги',
                            second: 'Юридичні консультації',
                            third: 'Податкові консультації'
                        }
                    }
                }
            }
        }
    }
}
