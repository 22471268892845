import React from 'react'
import { GeneralIconProps, Icon } from 'lib/components'

export const ArrowRight: React.FunctionComponent<GeneralIconProps> = props => (
    <Icon {...props}>
        <path d="M17 11C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13L17 11ZM3 13C2.44772 13 2 12.5523 2 12C2 11.4477 2.44772 11 3 11L3 13ZM17 13L3 13L3 11L17 11L17 13Z"/>
        <path d="M21.7152 11.7966L16.265 7.90356C15.7355 7.52535 15 7.90385 15 8.55455L15 15.4454C15 16.0961 15.7355 16.4746 16.265 16.0964L21.7152 12.2034C21.8548 12.1037 21.8548 11.8963 21.7152 11.7966Z"/>
    </Icon>
)

