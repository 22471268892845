import React from 'react'
import styled from 'styled-components'

type LinkProps = {
    href: string
}

export const Link: React.FunctionComponent<LinkProps> = ({
    children,
    href
}) => (
    <Tag
        href={href}
        target="_blank"
    >
        {children}
    </Tag>
)

const Tag = styled.a`
  outline: none;
  display: flex;
  cursor: pointer;
`
